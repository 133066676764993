import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import Home from "./Home";
import Contact from "./Contact";
import "./App.css"; // Ensure you have this line to import the CSS file
import logo from "./logo-no-bg.png"; // Import the logo image

function App() {
  return (
    <div>
      <header>
        <nav>
          <div className="logo">
            <NavLink to="/">
              <img src={logo} alt="Logo" />
            </NavLink>
          </div>
          <ul>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
