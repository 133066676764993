import React from "react";
import logo from "./logo-no-bg.png"; // Import the logo image

function Home() {
  return (
    <div className="home">
      <img src={logo} alt="Logo" className="home-logo" />
      <h2 className="home-subtitle">
        A Fundamental Global Equity Investment Manager
      </h2>
    </div>
  );
}

export default Home;
