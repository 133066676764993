import React from "react";

function Contact() {
  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>189 Loisaida Ave</p>
      <p>New York, NY 10009</p>
      <p>contact@crimson-crescent.com</p>
    </div>
  );
}

export default Contact;
